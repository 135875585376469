import React from 'react'
import '../scss/Multi.scss'

function Multi(props) {
    let final = []

    let firstSection = (
        <div key={"first"} className="brand-firstCont">
            <div className="brand-brand">BRANDS</div>
            <div className="brand-name">{props.data.name}</div>
            <div className="brand-text">{props.data.text}</div>
        </div>
    )

    let lastSection = (
        <div key={"last"} className="brand-lastCont brand-bg">
            <div className='brand-bottom'>
                <div className="brand-dealer brand-black">Our Locations</div>
                <div className="brand-dealerText">FIND THE CAR YOU WANT AT {props.group.groupName}</div>
                <a className="brand-visitWebsite" href={props.data.contact}>
                        <div>GET DIRECTIONS</div>
                        <i style={{ fontSize: "20px" }} className="las la-arrow-right"></i>
                    </a>
            </div>
        </div>
    )

    let dealers = props.group.dealers.map((dealer, i) => {
        return (
            <div key={i} style={{ backgroundImage: `url(${dealer.bgBackground})` }} className="brand-dealerCont">
                <div className="brand-bottom">
                    <div className="brand-dealer">{dealer.dealerName}</div>
                    {dealer.newInventory !== "" ?
                        <div className='brand-invCont'>
                            <a href={dealer.newInventory} className="brand-invText brand-border">NEW INVENTORY</a>
                            <a href={dealer.usedInventory} className="brand-invText brand-left">USED INVENTORY</a>
                        </div>
                        : ''}
                    <a className="brand-visitWebsite" href={dealer.websiteLink}>
                        <div>VISIT WEBSITE</div>
                        <i style={{ fontSize: "20px" }} className="las la-arrow-right"></i>
                    </a>
                </div>
            </div>
        )
    })

    final.push(firstSection)
    let newArray = final.concat(dealers)
    newArray.push(lastSection)

    return (
        <div className='multi-mainCont'>
            {newArray}
        </div>
    )
}

export default Multi

