import React from 'react'
import "../scss/About.scss"

function About(props) {
    return (
        <div className="aboutParent">
            <div className="aboutTop">ABOUT</div>
            <div className="aboutDealer">{props.data.title}</div>
            <div className="aboutText" dangerouslySetInnerHTML={{ __html: props.data.aboutText }}></div>
        </div>
    )
}

export default About