import React from 'react'
import MapContainer from './MapContainer'
import "../scss/Map.scss"


function Map(props) {
    let dealers = props.group.dealers.map((dealer, i) => {
        return (
            <div className="map-dealerCont" key={i}>
                <div className="map-dealerInfo">
                    <div className="map-dealerName">
                        <img alt="" className="map-dealerLogo" src={dealer.dealerLogo} />
                        <div className="map-dealerNameText">{dealer.dealerName}</div>
                    </div>
                    <div className="map-address">{dealer.dealerAddress}</div>
                    <div className='map-phoneCont'>
                        {dealer.salesNumber.length > 0 && <div className="map-sales">
                            <strong>Sales</strong> {dealer.salesNumber}
                        </div>}
                        {dealer.serviceNumber.length > 0 && <div className="map-service">
                            <strong>Service</strong> {dealer.serviceNumber}
                        </div>}
                    </div>
                    <a href={dealer.websiteLink} className="map-website">{dealer.website}</a>
                </div>
                <div className="map-buttonCont">
                    <a rel="noreferrer" target="_blank" href={dealer.directions} className="map-button">GET DIRECTIONS</a>
                    <a href={'tel:' + dealer.salesNumber} className="map-button">CALL US</a>
                </div>
            </div>
        )
    })

    return (
        <div className="googleHeight">
            <div className="mapBox">
                {dealers}
            </div>
            <MapContainer data={props.group.dealers} />
        </div>
    )
}

export default Map