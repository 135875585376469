import React from 'react'
import Slider from "react-slick"
import PropTypes from 'prop-types'
import "slick-carousel/slick/slick.css"
import "../scss/Slider.scss"

class SliderComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentSlide: 1,
            totalSlides: 0,
            paused: false,
            timer: '',
            percent: 0,
            slides: []
        }

        this.sliderButton = React.createRef()
        this.progressBar = React.createRef()
        this.slider = React.createRef()
        this.nav = React.createRef()
        this.startProgressBar = this.startProgressBar.bind(this)
        this.checkTime = this.checkTime.bind(this)
    }

    async componentDidMount() {
        await this.checkDates() // checks the expiration of slides before starting the slider

        this.startProgressBar() // starts progress bar
        this.setState({
            totalSlides: this.state.slides.length // assigns total slides length after expirations are checked
        })
    }

    
    checkDates() {
        return new Promise((resolve, reject) => {
            const today = new Date(Date.now()).getTime()
            let currentSlides = []

            this.props.data.Slider.value.forEach(slide => {
                const expiration = new Date(slide.expiration).getTime()
                const startDate = new Date(slide.startDate).getTime()

                if (startDate <= today && today < expiration) currentSlides.push(slide) // pushed slide into new array if today fits between expiration and start date
            })

            if (currentSlides.length === 1) currentSlides = currentSlides.concat(currentSlides) // have to add a second slide because the slider breaks with only 1 slide

            this.setState({
                slides: currentSlides
            })

            resolve(currentSlides)
        })
    }

    toggleSliderButton() {
        if (this.state.paused) {
            this.slider.slickPlay()
            this.setState({ paused: false })
            this.sliderButton.current.classList.remove('slider-control__btn_play')
        } else {
            this.slider.slickPause()
            this.setState({ paused: true })
            this.sliderButton.current.classList.add('slider-control__btn_play')
        }
    }

    startProgressBar() {
        this.slider.slickPlay()
        this.sliderButton.current.classList = 'slider-control__btn'

        this.setState({
            timer: setInterval(this.checkTime, 30),
            percent: 0,
            paused: false
        })
    }

    resetProgressBar() {
        clearTimeout(this.state.timer)
        this.progressBar.current.style.width = '0%'
        this.startProgressBar()
    }

    checkTime() {
        if (!this.state.paused) {
            this.setState({
                percent: this.state.percent + (100 / (10000 / 30))
            })

            this.progressBar.current.style.width = this.state.percent + '%'
        }

        if (this.state.percent >= 100) {
            this.slider.slickNext()
        }
    }

    render() {
        if (this.state.slides.length < 1) return <div></div>

        const mainSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            asNavFor: this.nav,
            afterChange: (index) => {
                this.resetProgressBar()

                this.setState({
                    currentSlide: index + 1
                })
            }
        }

        const navSettings = {
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            asNavFor: this.slider,
            variableWidth: true,
            centerMode: true,
            arrows: false
        }

        let slides = this.state.slides.map((slide, i) => {
            if (slide.textSlideBool) {
                return (
                    <div style={{ height: 750 }} aria-label={slide.label} key={i}>
                        <div className="slide-wrapper">
                            <div className="slide-picture-content">
                                <picture>
                                    <source srcSet={slide.imageSmall}
                                        media="(max-width: 550px)" />
                                    <source srcSet={slide.imageMedium}
                                        media="(max-width: 750px)" />
                                    <img className="slide-picture" src={slide.imageLink} alt={slide.alt} title={slide.title} />
                                </picture>
                            </div>
                            <div className="cta-slider-block">
                                <div className="sliderTextContainer">
                                    <div className="textSlide-cont">
                                        {slide.textSlideTitle !== '' ? <div className='textSlide-title'>{slide.textSlideTitle}</div>
                                            : ''}
                                    </div>
                                    <div>
                                        <div className="textSlide-para" dangerouslySetInnerHTML={{ __html: slide.textSlideParagraph }}></div>
                                    </div>
                                    <a href={slide.textSlideButtonLink} className="slideButton" style={{ backgroundColor: this.props.theme.colors.accent1 }}>{slide.textSlideButtonText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div style={{ height: 750 }} aria-label={slide.label} key={i}>
                    <div className="slide-wrapper">
                        <div className="slide-picture-content">
                            <picture>
                                <source srcSet={slide.imageSmall}
                                    media="(max-width: 550px)" />
                                <source srcSet={slide.imageMedium}
                                    media="(max-width: 750px)" />
                                <img className="slide-picture" src={slide.imageLink} alt={slide.alt} title={slide.title} />
                            </picture>
                        </div>
                        <div className="cta-slider-block">
                            <div className="sliderTextContainer">
                                <div className="titleContainer">
                                    <div className='titleOne'>{slide.titleOne}</div>
                                    <div className='titleTwo'>{slide.titleTwo}</div>
                                </div>
                                <div className="offerContainer">
                                    <div className="offerType">{slide.offerType}</div>
                                    <div className="price">${slide.price}</div>
                                    <div className="priceFrequency">/{slide.priceFrequency}</div>
                                    <div className="termInterestContainer">
                                        <div className="term">{slide.term} MONTH TERM</div>
                                        <div className="interestRate">{slide.interestRate}% INTEREST RATE</div>
                                    </div>
                                </div>
                                <div className="disclaimer"><span className="discName">Disclaimer:</span> {slide.disclaimer}</div>
                                <a href={slide.buttonLink} className="slideButton" style={{ backgroundColor: this.props.theme.colors.accent1 }}>{slide.buttonText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        let navSlides = this.state.slides.map((slide, i) => {
            return (
                <div key={i} style={{ width: 150 }}>
                    <div className="imageWindow">
                        <img className="navImage" src={slide.imageLink} alt={slide.alt} />
                    </div>
                </div>
            )
        })

        return (
            <div className="slider-wrapper home hero">
                <Slider ref={slider => (this.slider = slider)} {...mainSettings}>
                    {slides}
                </Slider>
                <div className="slider-control">
                    <div className="slider-control__left">
                        <button onClick={() => this.toggleSliderButton()} ref={this.sliderButton} style={{ backgroundColor: this.props.theme.colors.accent1 }} className="slider-control__btn">
                        </button>
                        <div className="slider-control__progress">
                            <div ref={this.progressBar} className="slider-control__progress-value" style={{ backgroundColor: this.props.theme.colors.accent1 }}></div>
                        </div>
                    </div>
                </div>
                <div className="slide-number">
                    <span>{this.state.currentSlide}</span>/<span>{this.state.totalSlides}</span>
                </div>
                <div className="sliderTwo slide-nav-wrap" //style={{ borderBottom: `2px solid ${this.props.theme.colors.accent1}` }}
                >
                    <div className="slide-nav-wrap__arrows"></div>
                    <Slider ref={slider => (this.nav = slider)} {...navSettings}>
                        {navSlides}
                    </Slider>
                </div>
            </div>
        )
    }
}

SliderComponent.propTypes = {
    data: PropTypes.object,
    theme: PropTypes.object
}

export default SliderComponent



