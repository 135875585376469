import React from "react"
import About from '../components/About'
import Map from '../components/Map'
import Layout from '../components/layout'
import SliderComponent from '../components/Slider'
import Multi from '../components/Multi'
import SEO from '../components/SEO'
import HTML from '../components/HTML'
import AlphaSlider from "../components/AlphaSlider"

function HomePage(props) {
    const { pageContext } = props
    const { pageContent, theme, dealerInfo } = pageContext

    let components = [
        <AlphaSlider key={"AlphaSlider"} data={pageContent.Alphaslider} theme={theme} />,
        <SliderComponent key={"Slider"} data={pageContent.Slider} theme={theme} />,
        <About key={"About"} data={pageContent.About} theme={theme} />,
        <Map key={"Map"} group={dealerInfo} data={pageContent.Map} theme={theme} />,
        <Multi key={"Multi"} group={dealerInfo} data={pageContent.Multi} theme={theme} />,
        <SEO key={"SEO"} title={"Home"} data={pageContent.SEO} />,
        <HTML key={"HTML"} data={pageContent.HTML} />,
    ]

    //let sortedComponents = components.sort((a, b) => parseFloat(a.props.data.order) - parseFloat(b.props.data.order)) // sorts the components based on json data

    //let visibleComponents = sortedComponents.filter(component => { // filters out the components that are not visible
   //     return component.props.data.visible === true
   // })

    if(pageContent.hasOwnProperty("arrayRawHTML")) {
        const { contents } = pageContent.arrayRawHTML
        const rawComponents = contents.value.map(element => {
            return <HTML data={{ 
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }
    
    console.log(pageContent)
    let sortedComponents = components.sort((a, b) =>
        parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
    ) // sorts the components based on json data
    // filters out the components that are not visible
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })

    return (
        <Layout>
            {visibleComponents}
        </Layout>
    )
}


export default HomePage